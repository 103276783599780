<template>
    <el-dialog
    :title="status==1?'添加高校':'编辑高校'"
    :visible.sync="show"
    width="50%"
    :before-close="handleClose">
    <div>
        <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="高校名称">
                <el-input v-model="form.title" style="width:400px;"></el-input>
            </el-form-item>
            <el-form-item label="LOGO">
                <upload-img :modeldata="form" @changeurl="" style="margin-top:15px;"></upload-img>
            </el-form-item>
            <el-form-item label="高校介绍">
                <el-input
                type="textarea"
                :rows="10"
                placeholder="请输入内容"
                v-model="form.content">
                </el-input>
            </el-form-item>
        </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleClose">确 定</el-button>
    </span>
    </el-dialog>
</template>
<script>
export default{
    props:{
        show:{
            type:Boolean,
            default:false
        },
        selectobj:{
            type:Object,
            default:null
        }
    },
    data(){
        return{
            status:1,
            form:{
                url:"",
            }
        }
    },
    methods:{
        handleClose(){
            this.$emit("update:show",false)
        }
    }
}
</script>


