<template>
    <div>
        <div class="wz">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>高校</el-breadcrumb-item>
                <el-breadcrumb-item>高校管理</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="box">
            <div class="serarch  flex align-center justify-between">
                <div class="searchleft">
                    <el-form :inline="true" :model="searchform" class="demo-form-inline">
                        <el-form-item label="高校名称">
                            <el-input v-model="searchform.workName"  placeholder="高校名称"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search"  @click="onSubmit"  >查询</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="searchright">
                    <el-button type="primary" icon="el-icon-circle-plus-outline" @click="openAdd">添加高校</el-button>
                </div>
            </div>
            <div class="list" v-loading="loading">
                <el-table
                :data="list"
                :header-cell-style="{
                    'font-weight': 'bold',
                    color: '#555555',
                    padding: '6px 0',
                }"
                stripe
                border
                style="width: 100%"
                >
                    <el-table-column prop="roleName" label="高校名称" >
                    </el-table-column>
                    <el-table-column prop="createTime" label="添加时间">
                    </el-table-column>
                    <el-table-column prop="address" width="160" label="操作">
                        <template slot-scope="scope">
                        <el-button
                            type="primary"
                            @click="handleCreate(scope.row.id)"
                            >编辑</el-button
                        >
                        <el-button
                            type="danger"
                            v-preventReClick
                            @click="deleteRole(scope.row.id)"
                            >删除</el-button
                        >
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <app-page
            :total="total"
            :pageSize="pageSize"
            :current="pageNum"
            @handleCurrentChange="currentchange"
            @handleSizeChange="handchange">
            </app-page>
        </div>
        <Addcolleges :show.sync="show" :selectobj="selectobj"></Addcolleges>
    </div>
</template>
<script>
import Addcolleges from './addColleges.vue'
export default{
    components:{
        Addcolleges,
    },
    data(){
        return{
            show:false,
            selectobj:null,
            loading:false,
            searchform:{},
            list:[],
            total: 15,
            pageNum: 1,
            pageSize: 10,
        }
    },
    methods:{
        openAdd(){
            this.selectobj=null
            this.show=true
        },
        onSubmit(){

        },
        getList(){

        },
        handchange(data) {
            //分页Size变化
            this.pageSize = data;
            this.pageNum = 1;
            this.getList()
        },
        currentchange(data) {
            //当前页变化
            this.pageNum = data;
            this.getList()
        },
    }
}
</script>

<style scoped>

</style>
